@import "../../assets/scss/variables.scss";

.descriptionEditor {
    height: 300px;
    margin-bottom: 40px;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}
